// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ExtendCSS, Flex, Inline, Text } from 'vcc-ui';
import { ModelStatsModelStatToDisplay } from './ModelStats.props';

const ModelStat: React.FC<
  ModelStatsModelStatToDisplay & {
    withAccent: boolean;
    withAccentDesktop: boolean;
    highlightColor: string;
    highlightColorDesktop: string;
    statsValueVariant: string;
  }
> = ({
  prefix,
  value,
  unit,
  label,
  withAccent = false,
  highlight = false,
  backgroundColor = false,
  withAccentDesktop,
  highlightColor,
  highlightColorDesktop,
  statsValueVariant = 'regular',
}) => (
  <Flex
    extend={wrapperCSS({
      highlight,
      backgroundColor,
      highlightColor,
      highlightColorDesktop,
    })}
  >
    <Text
      variant={statsValueVariant === 'small' ? 'ootah' : 'cook'}
      subStyle="emphasis"
      extend={valueWrapperCSS}
    >
      {prefix && (
        <Inline extend={valueDecorationCSS} data-testid="modelStats:prefix">
          {prefix}
        </Inline>
      )}
      <Inline
        extend={valueCSS({ withAccent, withAccentDesktop })}
        data-testid="modelStats:value"
      >
        {value}
      </Inline>
      {unit && (
        <Inline extend={valueDecorationCSS} data-testid="modelStats:unit">
          {unit}
        </Inline>
      )}
    </Text>
    <Text variant="columbus" extend={labelCSS} data-testid="modelStats:label">
      {label}
    </Text>
  </Flex>
);

export default ModelStat;

const wrapperCSS =
  ({
    highlight,
    backgroundColor,
    highlightColor,
    highlightColorDesktop,
  }: {
    highlight: boolean;
    backgroundColor: boolean;
    highlightColor: string;
    highlightColorDesktop: string;
  }): ExtendCSS =>
  ({ theme: { baselineSubGrid, color } }) => ({
    padding: `${baselineSubGrid * 3}px`,
    '& p': {
      ...(backgroundColor && { color: color.primitive.white }),
    },
    ...(highlight && { border: `${highlightColor} 2px solid` }),
    ...(backgroundColor && { backgroundColor: color.primitive.greenPositive }),

    fromM: {
      ...(highlight && { border: `${highlightColorDesktop} 2px solid` }),
      ...(backgroundColor && {
        backgroundColor: color.primitive.greenPositive,
      }),
      flexBasis: '19%',
    },
    untilM: {
      flexBasis: '50%',
    },
  });

const valueWrapperCSS: ExtendCSS = {
  flexDirection: 'row',
  whiteSpace: 'nowrap',
};

const valueDecorationCSS: ExtendCSS = {
  fontSize: '0.4em',
  whiteSpace: 'nowrap',
};

const valueCSS = ({
  withAccent,
  withAccentDesktop,
}: {
  withAccent: boolean;
  withAccentDesktop: boolean;
}) => ({
  lineHeight: '1em',
  ...(withAccent && {
    color: 'foreground.action',
  }),
  ...(withAccentDesktop && {
    fromM: {
      color: 'foreground.action',
    },
  }),
});

const labelCSS: ExtendCSS = ({ theme: { color } }) => ({
  color: color.foreground.secondary,
});
