import React from 'react';
import { ExtendCSS, Flex, Spacer, Text } from 'vcc-ui';
import {
  maxContentWidth10Columns,
  maxContentWidth12Columns,
  maxContentWidth8Columns,
} from '@vcc-www/constants/layout';
import SectionContained from '@vcc-www/page-section/DEPRECATED_BROKEN_SectionContained';
import Disclaimer from '@vcc-www/disclaimer';
import AnimatedReveal from '@vcc-www/animated-reveal';
import { Link } from '@vcc-www/buttons';
import Props from './ModelStats.props';
import ModelStat from './ModelStat';

const ModelStats: React.FC<Props> = ({
  content: {
    disclaimer,
    statsToDisplay,
    title,
    description,
    moreDetailsLink,
    disableHover,
  },
  highlightColor = 'accentBlue',
  highlightColorDesktop = 'accentBlue',
  withAccent = false,
  withAccentDesktop = withAccent,
  marginsOnMobile = false,
  statsValueVariant = 'regular',
  gridLayoutSize = '8',
  ...params
}) => {
  const hasText = !!title || !!description;
  const stats = statsToDisplay?.slice(0, 4);

  const textCSSAlignment = {
    textAlign: params?.textAlignment || 'left',
  };

  // Prevent passing the textAlignment to the DOM
  const paramsSansTextAlignment = { ...params };
  delete paramsSansTextAlignment.textAlignment;

  return (
    <SectionContained
      {...paramsSansTextAlignment}
      marginsOnMobile={marginsOnMobile}
      extend={sectionCSS({ gridLayoutSize })}
    >
      {(title || description) && (
        <Flex extend={{ ...textCSS, ...textCSSAlignment }}>
          {title && <p className="font-20 font-medium">{title}</p>}

          {description && (
            <Text
              variant="columbus"
              subStyle={disableHover ? 'standard' : 'inline-link'}
              extend={descriptionCSS({ disableHover })}
            >
              {description}
            </Text>
          )}
        </Flex>
      )}

      {stats && (
        <Flex
          extend={statsOuterWrapperCSS({
            hasText,
            gridLayoutSize,
            disableHover,
          })}
        >
          <Flex extend={statsInnerWrapperCSS} data-autoid="modelStats:value">
            {stats?.map((stat, i) => (
              <AnimatedReveal key={i} stagger={i}>
                <ModelStat
                  {...stat}
                  withAccent={withAccent}
                  withAccentDesktop={withAccentDesktop}
                  highlightColor={highlightColor}
                  highlightColorDesktop={highlightColorDesktop}
                  statsValueVariant={statsValueVariant}
                />
              </AnimatedReveal>
            ))}
          </Flex>
        </Flex>
      )}

      {moreDetailsLink?.text && moreDetailsLink?.href && (
        <AnimatedReveal stagger={(stats?.length || 0) - 1} rootMargin={20}>
          <Flex
            extend={specificationPageCSS}
            data-testid="modelStats:moreDetailsContainer"
          >
            <Link
              href={moreDetailsLink.href}
              target={moreDetailsLink.target}
              trackEventLabel={`${moreDetailsLink.text} | ${moreDetailsLink.href}`}
              trackData={{
                eventCategory: 'more details link 1',
              }}
            >
              {moreDetailsLink.text}
            </Link>
          </Flex>
        </AnimatedReveal>
      )}

      {!!disclaimer && (
        <AnimatedReveal stagger={stats?.length || 0} rootMargin={20}>
          <Flex
            extend={disclaimerWrapperCSS}
            data-testid="modelStats:disclaimer"
            data-autoid="modelStats:disclaimer"
          >
            <Spacer size={4} />
            <Disclaimer text={disclaimer} />
          </Flex>
        </AnimatedReveal>
      )}
    </SectionContained>
  );
};

export default ModelStats;

const sectionCSS =
  ({ gridLayoutSize }: Pick<Props, 'gridLayoutSize'>): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    display: 'flex',
    flexDirection: 'column',
    untilM: {
      // TODO: check that this doesn't break the old implementation
      paddingLeft: baselineGrid * 3,
      paddingRight: baselineGrid * 3,
    },
    // TODO: check that this doesn't break the old implementation
    fromL: {
      maxWidth:
        gridLayoutSize === '10'
          ? maxContentWidth10Columns
          : maxContentWidth8Columns,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  });

const textCSS: ExtendCSS = () => ({
  maxWidth: '100%',
  fromM: {
    minWidth: maxContentWidth12Columns / 2,
  },
});

const descriptionCSS =
  ({ disableHover }: { disableHover: boolean | undefined }): ExtendCSS =>
  ({ theme: { baselineGrid, color } }) => ({
    marginTop: baselineGrid * 3,
    ...(disableHover && { color: color.primitive.grey200 }),
  });

const statsOuterWrapperCSS =
  ({
    hasText,
    gridLayoutSize,
    disableHover,
  }: {
    hasText: boolean;
    disableHover: boolean | undefined;
    gridLayoutSize: Props['gridLayoutSize'];
  }): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    maxWidth: '100%',
    minWidth: maxContentWidth12Columns / 2,
    margin:
      gridLayoutSize === '10' ? 0 : `${hasText ? baselineGrid * 5 : 0}px auto`,
    ...(disableHover && { margin: 0 }),
    untilL: {
      minWidth: '70%',
    },
  });

const statsInnerWrapperCSS: ExtendCSS = ({ theme: { baselineSubGrid } }) => ({
  justifyContent: 'space-between',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignSelf: 'stretch',
  margin: `-${baselineSubGrid * 3}px`,
});

const disclaimerWrapperCSS: ExtendCSS = {
  maxWidth: '100%',
  width: maxContentWidth12Columns / 2,
  alignSelf: 'stretch',
  flex: '1 1 auto',
  fromM: {
    alignSelf: 'center',
    textAlign: 'center',
  },
};

const specificationPageCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  maxWidth: '100%',
  display: 'flex',
  alignSelf: 'center',
  marginTop: baselineGrid * 3,
  untilM: {
    alignSelf: 'self-start',
  },
});
